import { Injectable, computed, inject, signal } from '@angular/core';
import { ObservableInput, catchError, throwError } from 'rxjs';

import { ICreateWorkspaceRequest, IWorkspace, Maybe } from '@site-mate/sitemate-flowsite-shared';
import { PathUtility } from '@site-mate/sitemate-global-shared';

import { FlowsiteApiHttpClient } from 'app/core/http/clients/api.client';

import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class WorkspaceService {
  private readonly api = inject(FlowsiteApiHttpClient);
  private readonly localStorageService = inject(LocalStorageService);

  private _currentWorkspace = signal<IWorkspace>({
    _id: '',
    name: '',
    sitemateAccountId: '',
  } as IWorkspace);

  /**
   * Value becomes available once route resolver is completed
   */
  currentWorkspace = this._currentWorkspace.asReadonly();

  /**
   * Get current workspace id without the '/' prefix
   */
  currentWorkspaceId = computed(() => PathUtility.getLeaf(this.currentWorkspace()._id));

  public setCurrentWorkspace(workspace: IWorkspace) {
    this._currentWorkspace.set(workspace);
    this.localStorageService.set(
      LocalStorageService.ItemKey.LastVisitedWorkspaceId,
      PathUtility.getLeaf(workspace._id),
    );
  }

  public getLastVisitedWorkspaceId() {
    return this.localStorageService.get(LocalStorageService.ItemKey.LastVisitedWorkspaceId);
  }

  public getWorkspaces() {
    return this.api.get<IWorkspace[]>('/workspaces');
  }

  public getWorkspace(workspaceId: string) {
    return this.api.get<IWorkspace>(`/workspaces/${workspaceId}`);
  }

  public getWorkspacesByAccountId(accountId: string) {
    return this.api.get<IWorkspace[]>(`/accounts/${accountId}/workspaces`);
  }

  public createWorkspace(workspaceRequest: ICreateWorkspaceRequest) {
    return this.api.post<IWorkspace>('/workspaces', workspaceRequest);
  }

  public updateWorkspace(workspaceId: string, workspace: Partial<IWorkspace>) {
    const currentWorkspace = this.currentWorkspace();
    let workspaceRollbackValue: Maybe<IWorkspace>;
    if (workspaceId === PathUtility.getLeaf(currentWorkspace._id)) {
      const data = { ...currentWorkspace, ...workspace };
      this._currentWorkspace.set(data);
      workspaceRollbackValue = currentWorkspace;
    }

    return this.api
      .patch<IWorkspace>(`/workspaces/${workspaceId}`, workspace)
      .pipe(this.handleUpdateError('Failed to update workspace', workspaceRollbackValue));
  }

  public updateWorkspaceName(workspaceId: string, name: string) {
    const currentWorkspace = this.currentWorkspace();
    let workspaceRollbackValue: Maybe<IWorkspace>;
    if (workspaceId === PathUtility.getLeaf(currentWorkspace._id)) {
      const data = { ...currentWorkspace, name };
      this._currentWorkspace.set(data);
      workspaceRollbackValue = currentWorkspace;
    }

    return this.api
      .patch<IWorkspace>(`/workspaces/${workspaceId}/name`, { name })
      .pipe(this.handleUpdateError('Failed to update workspace name', workspaceRollbackValue));
  }

  private handleUpdateError(errorMsg: string, workspaceRollbackValue?: IWorkspace) {
    return catchError<IWorkspace, ObservableInput<any>>((error) => {
      // TODO: implement toast notifications for errors
      console.error(`${WorkspaceService.name}: ${errorMsg}`, error);
      if (workspaceRollbackValue) {
        this._currentWorkspace.set(workspaceRollbackValue);
      }
      return throwError(() => new Error(errorMsg));
    });
  }
}
