import { Injectable } from '@angular/core';

enum LocalStorageItemKeys {
  LastVisitedWorkspaceId = 'lastVisitedWorkspaceId',
  CustomRedirectURL = 'customRedirectURL',
}

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  public get(key: LocalStorageItemKeys): string | null {
    return localStorage.getItem(key);
  }

  public set(key: LocalStorageItemKeys, value: string): void {
    localStorage.setItem(key, value);
  }

  public remove(key: LocalStorageItemKeys): void {
    localStorage.removeItem(key);
  }

  public static readonly ItemKey = LocalStorageItemKeys;
}
